/* src/index.css */
@import url("https://fonts.googleapis.com/css2?family=Bai+Jamjuree:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;
/* src/index.css or main stylesheet */
@import "../src/Styles/animation.css";

body {
  @apply font-sans;
  --swiper-navigation-size: 30px; /* Controls the icon size */
  --swiper-navigation-sides-offset: 20px;
}
/* Changing the color of the Swiper navigation buttons */
.swiper-button-next,
.swiper-button-prev {
  color: #15f5ba; /* Set your desired color */
}
