/* animations.css */
.slide-up {
  opacity: 0;
  transform: translateY(30px); /* Subtle entry effect */
  transition:
    opacity 0.4s ease-out,
    transform 0.4s ease-out; /* Entry transition */
}

.slide-up.visible {
  opacity: 1;
  transform: translateY(0); /* Final position for entry */
}

/* Slide down animation */
.slide-up.slide-down {
  opacity: 1; /* Keep it visible while sliding down */
  transform: translateY(
    30px
  ); /* Slide back to the position from where it came */
  transition:
    opacity 0.4s ease-out,
    transform 0.4s ease-out; /* Transition for slide down */
}

.slide-up.slide-down.fade-out {
  opacity: 0; /* Fade out effect */
  transform: translateY(100px); /* Move down by 100px */
}
